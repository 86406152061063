import axios from 'axios';
import { checkSessionStorageItem } from '../../utils/sessionStorage';
import { SESSION_STORAGE_CLIENT } from '../../utils/constants';

export const validateEmail = async (email) => {
  const response = await axios.get(`email/validate/${email}`);

  return response;
};

export const getStepOneCustomFields = async () => {
  try {
    const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);

    const generalApi = 'wizard/has_custom_field/';
    const apiUrl = generalApi + (hasClient ? `${client}` : 'general');
    const response = await axios.get(apiUrl);

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};

export const getCountries = async () => {
  try {
    const apiUrl = 'countries';

    const response = await axios.get(apiUrl);

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};

export const getStep3 = async (plan, mode) => {
  try {
    const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);
    const generalApi = 'wizard/step_3/';

    const apiUrl =
      generalApi + (hasClient ? `${client}/${plan}?page=${mode}` : `general/${plan}?page=${mode}`);

    const response = await axios.get(apiUrl);

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};

export const getAccordionDetails = async (planId) => {
  const response = await axios.get(`wizard/plan?plan_id=${planId}`);

  return response?.data;
};

export const discountCodeValidator = async (discountCode) => {
  try {
    const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);
    const clientCode = hasClient ? client : 'general';

    const response = await axios.get(
      `coupon_code_validator?client=${clientCode}&code=${discountCode}`
    );

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};

export const getPriceSummary = async (payload) => {
  try {
    const { planId, expatId, petId, couponCode } = payload;

    const response = await axios.get(
      `wizard_price_calculator?plan_id=${planId}&exp_services=${expatId}&pets_services=${petId}&coupon_code=${couponCode}`
    );

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};

export const getPolicyRenewalSummary = async (payload) => {
  try {
    const { planId, expatId, petId } = payload;

    const response = await axios.get(
      `wizard_renewal_price_calculator?plan_id=${planId}&exp_services=${expatId}&pets_services=${petId}`,
      { requiresAuth: true }
    );

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};

export const submitWizard = async (payload) => {
  try {
    const response = await axios.post(`wizard_submit`, payload);

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};

export const submitPolicyRenewal = async (payload) => {
  try {
    const response = await axios.post(`policy_renewal`, payload, { requiresAuth: true });

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};

export const getPaymentKeys = async () => {
  try {
    const response = await axios.get(`authorize_credentials`, {
      auth: {
        username: process.env.REACT_APP_BASIC_AUTH,
        password: process.env.REACT_APP_BASIC_AUTH_PASS
      }
    });

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};

export const getUpdateUserWizardInfo = async () => {
  try {
    const response = await axios.get(`customer-data`, { requiresAuth: true });

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};

export const updateUpdateUserWizardInfo = async (payload) => {
  try {
    const response = await axios.put(`customer-data`, payload, { requiresAuth: true });

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};

export const getUpdatePriceSummary = async (payload) => {
  const { expatId, petId } = payload;

  try {
    const response = await axios.get(
      `wizard_update_price_calculator?exp_services=${expatId}&pets_services=${petId}`,
      { requiresAuth: true }
    );

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};
