import { Grid, Typography } from "@mui/material";
import React from "react";

function Facility({ img, name, description }) {
  return (
    <Grid container pb={3} columnSpacing={{ xs: 4, md:0 }}>
      <Grid item xs={2} md={2} align="center">
        <img src={img} alt="" width={50} height={50}/>
      </Grid>
      <Grid item md={10} xs={10}>
        <Typography
          sx={{
            fontFamily: "Urbanist",
            fontSize: "20px",
            fontWeight: "700",
            lineHeight: "24px",
          }}
        >
          {name}
        </Typography>
        <Typography variant="body" dangerouslySetInnerHTML={{ __html: description }} />

      </Grid>
    </Grid>
  );
}

export default Facility;
