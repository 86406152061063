import { Container, Grid, Typography, Button } from '@mui/material';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import LottieAnimation from '../LottieAnimation';
import animationData from '../../static/hp_animation.json';
import { checkSessionStorageItem } from '../../utils/sessionStorage';
import { SESSION_STORAGE_CLIENT } from '../../utils/constants';

export default function Banner({ title, button, logo }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLoggedIn = sessionStorage.getItem('isLoggedIn');

  const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);

  const navigateTo = hasClient ? `${client}/plans` : `/plans`;

  return (
    <Grid
      pt={10}
      sx={{
        backgroundImage: 'url("/images/hp-header-bg.svg")',
        backgroundSize: 'cover'
      }}>
      <Container maxWidth="xl">
        <Grid
          container
          py={{ md: 5, xs: 0 }}
          columnSpacing={{ md: 4, xs: 0 }}
          sx={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}>
          <Grid item xl={5} md={6} sm={6} xs={12} pt={{ md: 7, xs: 3 }}>
          {logo && (
              <img
                src={process.env.REACT_APP_BACKEND_URL + logo}
                alt="client logo"
                style={{ width: isMobile ? '200px' : '250px' }}
              />
            )}
            <Typography variant="heading1" dangerouslySetInnerHTML={{ __html: title }} />
            {!isLoggedIn && (
              <Button variant="contained" href={navigateTo} sx={{ mt: 5 }}>
                {button}
              </Button>
            )}
          </Grid>
          <Grid item xl={7} md={6} sm={6} xs={12} sx={{ position: 'relative' }}>
            <img className="hp_image_1" src="/images/hp_image_1.png" alt="homepage animation 1" />
            <img className="hp_image_2" src="/images/hp_image_2.png" alt="homepage animation 2" />
            <img className="hp_image_3" src="/images/hp_image_3.png" alt="homepage animation 3" />

            <LottieAnimation animationData={animationData} looping={false} />
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
