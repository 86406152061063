import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { Fragment, useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import Checklist from '../../components/Checklist';
import { COLORS } from '../../utils/colors';
import DownloadMobileApp from '../../components/DownloadMobileApp';

import { ContactUsContainer } from './WelcomPage.styles';
import { ReactComponent as PhoneIcon } from '../../assets/ic-phone-number.svg';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getWelcomePage } from './welcomePage.api';
import PageLoader from '../../components/PageLoader';
import { useParams, Link } from 'react-router-dom';

import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth';
import ServerError from '../Static/ServerError';

export default function WelcomePage() {
  const theme = useTheme();
  const { token } = useParams();
  const isTabletAndSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const overlayStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#0000004D'
  };

  const gridStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  };

  const titleStyles = {
    color: '#fff',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  };

  const imageBoxStyles = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: isMobile ? '150px' : '250px'
  });

  const contactUsImageStyles = {
    width: '361px',
    height: '100%',
    borderRadius: '10px'
  };

  const { data, isLoading, error } = useQuery(['welcomePage', token], () => getWelcomePage(token), {
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 240000,
    refetchOnReconnect: false
  });

  const {
    body,
    user,
    policy,
    summary,
    services_included,
    conditions,
    exclusions,
    contact_us,
    download_app,
    banner
  } = data || {};

  const copyToClipboard = useCallback((text) => {
    let isCopy = copy(text);

    if (isCopy) {
      toast.success('Copied to Clipboard');
    }
  }, []);

  const renderDownloadApp = () => (
    <DownloadMobileApp
      title={download_app?.title}
      body={download_app?.body}
      play_store_url={download_app?.links?.app_store}
      google_play_url={download_app?.links?.google_play}
      image={download_app?.image}
      showOnLargeScreens
    />
  );

  const renderContactUsBox = () => (
    <ContactUsContainer>
      <Grid item flex={1.4}>
        <Grid maxWidth={{ xl: '70%', lg: '70%', md: '100%' }}>
          <Typography variant="heading1" color={'white'} fontWeight={600}>
            {contact_us?.title}
          </Typography>
        </Grid>
        <Grid mt={2} mb={4}>
          <Typography
            variant="body"
            fontWeight={500}
            color={'white'}
            paragraph={true}
            dangerouslySetInnerHTML={{ __html: contact_us?.body }}
          />
        </Grid>
        <Grid container>
          {contact_us?.elements?.map((service, index) => (
            <Grid item key={index} xs={12} sm={6} mb={3}>
              <Checklist name={service} colorVariant={'secondary'} />
            </Grid>
          ))}
        </Grid>
        <Typography
          variant="body"
          fontWeight={500}
          color={'white'}
          paragraph={true}
          dangerouslySetInnerHTML={{ __html: contact_us?.summary }}
        />

        <Grid display={'flex'} mt={4.185} justifyContent={'flex-start'} alignItems={'flex-start'}>
          <Grid mr={2.9} pt={0.6}>
            <PhoneIcon />
          </Grid>
          <Grid display={'flex'} flexDirection={'column'}>
            <Typography
              variant="body"
              fontWeight={500}
              color={'white'}
              paragraph={true}
              dangerouslySetInnerHTML={{ __html: contact_us?.hotline?.[0]?.text }}
            />
            <Typography variant="body" fontWeight={500} color={'#FFFFFFCC'}>
              {contact_us?.hotline?.[0]?.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {!isTabletAndSmaller && (
        <Grid item flex={1} justifyContent={'flex-end'} display={'flex'}>
          <img
            src={process.env.REACT_APP_BACKEND_URL + contact_us?.image}
            style={contactUsImageStyles}
          />
        </Grid>
      )}
    </ContactUsContainer>
  );

  const renderPolicyInformation = () => (
    <Grid>
      <Typography variant="bodyTitle">{body?.hello}</Typography>
      <Grid mt={2.5}>
        <Typography variant="body" color={COLORS.black}>
          {body?.welcome}
        </Typography>
      </Grid>
      <Grid display={'flex'} mt={2.5} mr={1} flexDirection={isTabletAndSmaller ? 'column' : 'row'}>
        <Grid mr={0.5}>
          <Typography variant="body" color={COLORS.black}>
            {body?.reference_number_text}
          </Typography>
        </Grid>

        <Typography color={COLORS.green}>{body?.reference_number}</Typography>
      </Grid>
      <Grid mt={4}>
        <Typography
          variant="body"
          color={'#10182F80'}
          dangerouslySetInnerHTML={{ __html: body?.text }}
        />
      </Grid>

      <Grid container gap={2} mt={4}>
        {renderPolicyItems(
          'Login URL',
          `${process.env.REACT_APP_DOMAIN_URL}login`,
          true,
          false,
          false,
          { email: user?.email }
        )}
        {renderPolicyItems('GTP Plan', policy?.plan_type, false)}
        {renderPolicyItems('Email Address', user?.email, false)}
        {renderPolicyItems('Temporary Password', user?.password, false, true)}
        {renderPolicyItems('Cost', `$ ${policy?.cost}/yr`, false)}
      </Grid>
    </Grid>
  );

  const renderPolicyItems = (
    title,
    text,
    isLink = false,
    hasCopyButton = false,
    hasDownload = false,
    state
  ) => (
    <Grid
      item
      xs={isLink && !hasDownload ? 11 : 5}
      md={4}
      flexDirection={'column'}
      display={'flex'}>
      <Typography fontWeight={700} variant="smallText" color={COLORS.black}>
        {title}
      </Typography>
      {isLink && (
        <Link
          p={0}
          mt={1}
          rel="noopener noreferrer"
          to={text}
          state={state}
          style={{ color: '#5CB5A2' }}>
          {text}
        </Link>
      )}
      {!isLink && (
        <Grid>
          <Typography
            p={0}
            mt={1}
            variant="body"
            color={COLORS.black}
            sx={{ wordWrap: 'break-word' }}>
            {text}
          </Typography>
          {hasCopyButton && (
            <IconButton onClick={() => copyToClipboard(text)}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          )}
        </Grid>
      )}
    </Grid>
  );

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.welcome.name);

  return (
    <Fragment>
      <Grid container pt={8} pb={5}>
        <Grid sx={{ position: 'relative' }} item md={12} xs={12}>
          <Box sx={imageBoxStyles(process.env.REACT_APP_BACKEND_URL + banner?.image)}>
            <Box sx={overlayStyles}></Box>
          </Box>
          <Grid sx={gridStyles}>
            <Typography variant="heading2" sx={titleStyles}>
              {banner?.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {isLoading && <PageLoader />}
      {!isLoading && error && <ServerError />}
      {!isLoading && !error && (
        <Container maxWidth="xl">
          <Grid pt={isTabletAndSmaller ? 0 : 12} columnSpacing={15} rowSpacing={{ xs: 5, md: 0 }}>
            {renderPolicyInformation()}
            {renderContactUsBox()}
            <Typography
              variant="body"
              color={'#10182F80'}
              paragraph={true}
              dangerouslySetInnerHTML={{ __html: summary }}
            />

            {services_included?.length > 0 && (
              <Grid mt={4.25} mb={4.25}>
                <Typography variant="headerDescription" fontWeight={'600'} color={COLORS.darkBlack}>
                  Services included
                </Typography>
                <Grid container mt={3}>
                  {services_included?.map((service, index) => (
                    <Grid key={index} item xl={4} lg={4} md={6} sm={6} xs={12} mb={3}>
                      <Checklist name={service} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}

            <Typography variant="headerDescription" fontWeight={'600'} color={COLORS.darkBlack}>
              Conditions
            </Typography>

            {conditions?.length > 0 &&
              conditions?.map((item, index) => {
                const { text, elements } = item;

                return (
                  <Grid flexDirection={'column'} display={'flex'} key={index} mt={4.5}>
                    <Typography
                      variant="body"
                      fontWeight={'700'}
                      color={COLORS.black}
                      mt={2}
                      paragraph={true}
                      dangerouslySetInnerHTML={{ __html: text }}
                    />

                    <Grid container mt={3}>
                      {elements?.map((service, index) => (
                        <Grid key={index} item sm={12} xs={12} mb={2}>
                          <Checklist name={service} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                );
              })}
            {/* here goes this list of exclusions */}
            {exclusions && (
              <Grid flexDirection={'column'} display={'flex'} mt={8}>
                <Typography variant="headerDescription" fontWeight={'600'} color={COLORS.darkBlack}>
                  Exclusions
                </Typography>
                <Grid mt={3}>
                  <Typography
                    variant="body"
                    color={'#10182F80'}
                    paragraph={true}
                    dangerouslySetInnerHTML={{ __html: exclusions?.[0]?.text }}
                  />
                </Grid>
              </Grid>
            )}

            <Grid mt={5.8}>{renderDownloadApp()}</Grid>
          </Grid>
        </Container>
      )}
    </Fragment>
  );
}
