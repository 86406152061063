import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { decode, encode } from 'base-64';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

if (!window.btoa) {
  window.btoa = encode;
}

if (!window.atob) {
  window.atob = decode;
}

ReactDOM.render(
  <>
    <ToastContainer
      position="bottom-right"
      autoClose={500}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
    <App />
  </>,
  document.getElementById('root')
);
