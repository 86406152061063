import axios from "axios";

export const blogsList = async () => {
  const response = await axios.get("/travel_blog");

  return response?.data;
};

export const blog = async (id) => {
  const response = await axios.get(`/travel_blog/${id}`);
  
  return response?.data;
};
