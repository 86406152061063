import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import Carousel from "./Carousel";

export default function Feedbacks({ title, description, list }) {
  return (
    <Grid
      sx={{
        backgroundImage: 'url("/images/hp-feedbacks-bg.svg")',
        backgroundSize: "cover",
      }}
    >
      <Container maxWidth="xl">
        <Grid container pt={{xs:0, md:10}} pb={{xs:10, md:10}} justifyContent='space-between'>
          <Grid item md={3} xs={12}>
            <Typography
              paragraph={true}
              variant="heading2"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <Typography
            sx={{pr:2, width:'100%'}}
            paragraph={true}
              variant="subtitle"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <Carousel list={list}/>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
