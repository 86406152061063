import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default function Carousel({ list }) {
  const CustomArrow = ({ className, onClick, icon }) => {
    return (
      <div className={className} onClick={onClick}>
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  };

  const settings = {
    className: 'slider-container',
    centerMode: false,
    dots: true,
    useTransform: true,
    infinite: false,
    centerPadding: '80px',
    slidesToShow: 2,
    customPaging: function () {
      return <span className="dot"></span>;
    },
    nextArrow: <CustomArrow icon={faArrowRight} />,
    prevArrow: <CustomArrow icon={faArrowLeft} />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {list?.map((item) => (
        <div key={item.id}>
          <Card sx={{ borderRadius: '10px', boxShadow: 'none', p: 4, mr: 2 }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '20px',
                    fontWeight: '600'
                  }}>
                  {item.title}
                </Typography>
              </Grid>
              <Grid sx={{ fontSize: '35px', color: '#FFC27A' }} item>
                <FontAwesomeIcon icon={faQuoteRight} />
              </Grid>
            </Grid>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: '400',
                color: 'rgba(16, 24, 47, 0.5)'
              }}>
              {item.position}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: '400',
                color: 'rgba(16, 24, 47, 1)',
                minHeight: '185px'
              }}
              paragraph={true}
              dangerouslySetInnerHTML={{ __html: item.text }}
            />
          </Card>
        </div>
      ))}
    </Slider>
  );
}
