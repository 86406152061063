import * as React from 'react';
import {
  Grid,
  Container,
  Typography,
  Box,
  ListItemText,
  ListItem,
  ListItemIcon
} from '@mui/material';
import { useQuery } from 'react-query';
import { footer } from './APIRequests.js';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import {
  faFacebookF,
  faXTwitter,
  faInstagram,
  faLinkedinIn
} from '@fortawesome/free-brands-svg-icons';

const iconButtonStyles = {
  color: '#A2AED2',
  minWidth: '35px',
  border: '2px solid #A2AED2',
  width: '35px',
  height: '35px',
  fontSize: '16px',
  marginRight: '10px'
};

const contactIconStyles = {
  color: '#5CB5A2',
  minWidth: '35px'
};

export default function Footer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { data, isLoading } = useQuery('footer', footer);

  const social = [
    { name: 'Facebook', icon: faFacebookF, href: data?.social_media_fb },
    { name: 'LinkedIn', icon: faLinkedinIn, href: data?.social_media_linkedin },
    { name: 'Instagram', icon: faInstagram, href: data?.social_media_insta },
    { name: 'X', icon: faXTwitter, href: data?.social_media_x }
  ];

  const navigate = useNavigate();

  const handleNavigate = (href) => {
    navigate(href);
  };

  const googleMapsLink = `https://maps.app.goo.gl/ssuRB2hNnzTgkyJR9`;

  return (
    !isLoading && (
      <Box component={'footer'}>
        <Grid
          sx={{
            backgroundImage: 'url("/images/footer-bg.png")',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#14263A',
            color: '#A2AED2'
          }}>
          <Container maxWidth="xl">
            <Grid container justifyContent="space-between" py={5}>
              <Grid item md={7} xs={12}>
                <Link to="/">
                  <Box
                    sx={{ mr: 1,height: '50px' }}
                    component="img"
                    alt="Global Travel Plus"
                    src={process.env.REACT_APP_BACKEND_URL + (data?.logo ?? '')}
                  />
                </Link>
                <Typography mt={4}>{data?.description}</Typography>
                <Grid mt={3} sx={{ display: { xs: 'none', md: 'flex' } }}>
                  {social.map((s) => (
                    <IconButton
                      key={s.name}
                      onClick={() => window.open(s.href)}
                      sx={iconButtonStyles}>
                      <FontAwesomeIcon icon={s.icon} />
                    </IconButton>
                  ))}
                </Grid>
              </Grid>
              <Grid item md={3} xs={12} mt={{ md: 0, xs: 3 }}>
                <Typography paragraph={true} variant="footer_title">
                  CONTACT US
                </Typography>
                <ListItem
                  component="a"
                  href={`tel:${data?.contact_phone_nb}`}
                  sx={{ color: 'inherit' }}>
                  <ListItemIcon sx={contactIconStyles}>
                    <PhoneOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={data?.contact_phone_nb} />
                </ListItem>
                <ListItem
                  component="a"
                  href={`mailto:${data?.contact_email}`}
                  sx={{ color: 'inherit' }}>
                  <ListItemIcon sx={contactIconStyles}>
                    <EmailOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={data?.contact_email} />
                </ListItem>
                <ListItem
                  component="a"
                  href={googleMapsLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: 'inherit' }} 
                >
                  <ListItemIcon sx={contactIconStyles}>
                    <LocationOnOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={data?.contact_address} />
                </ListItem>
              </Grid>
              <Grid mt={3} sx={{ display: { xs: 'flex', md: 'none' } }}>
                {social.map((s) => (
                  <IconButton
                    key={s.name}
                    onClick={() => window.open(s.href)}
                    sx={iconButtonStyles}>
                    <FontAwesomeIcon icon={s.icon} />
                  </IconButton>
                ))}
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <Grid sx={{ backgroundColor: '#0F2134' }} py={{ md: 1, xs: 2 }}>
          <Container maxWidth="xl">
            <Grid container align={isMobile ? 'center' : 'left'}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    color: '#A2AED2',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: 400
                  }}>
                  {data?.copyrights}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} mt={{ xs: 2, md: 0 }}>
                <Grid container spacing={2} justifyContent={isMobile ? 'center' : 'flex-end'}>
                  <Grid item>
                    <Typography
                      sx={{
                        color: '#A2AED2',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 400,
                        cursor: 'pointer'
                      }}
                      onClick={() => handleNavigate('/privacy')}>
                      Privacy Policy
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: '#A2AED2',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 400,
                        cursor: 'pointer'
                      }}
                      onClick={() => handleNavigate('/terms')}>
                      Terms & Conditions
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Box>
    )
  );
}
