import { ReactComponent as InsuranceGreyIcon } from '../../assets/ic-insurance-grey.svg';
import { ReactComponent as InsuranceWhiteIcon } from '../../assets/ic-insurance-white.svg';
import { ReactComponent as InsuranceBlackIcon } from '../../assets/ic-insurance-black.svg';
import { ReactComponent as PersonalInfoWhiteIcon } from '../../assets/ic-personal-info-white.svg';
import { ReactComponent as PersonalInfoBlackIcon } from '../../assets/ic-personal-info-black.svg';
import { ReactComponent as PaymentInfoGreyIcon } from '../../assets/ic-payment-info-grey.svg';
import { ReactComponent as PaymentInfoWhiteIcon } from '../../assets/ic-payment-info-white.svg';
import { ReactComponent as PlanTypeGreyIcon } from '../../assets/ic-plan-type-grey.svg';
import { ReactComponent as PlanTypeBlackIcon } from '../../assets/ic-plan-type-black.svg';
import { ReactComponent as PlanTypeWhiteIcon } from '../../assets/ic-plan-type-white.svg';
import { ColorlibStepIconRoot } from './Wizard.styles';

export function ColorlibStepIcon(props) {
  const { active, completed } = props;

  const icons = {
    1: {
      default: null,
      active: <PersonalInfoWhiteIcon />,
      completed: <PersonalInfoBlackIcon />
    },
    2: {
      default: <InsuranceGreyIcon />,
      active: <InsuranceWhiteIcon />,
      completed: <InsuranceBlackIcon />
    },
    3: {
      default: <PlanTypeGreyIcon />,
      active: <PlanTypeWhiteIcon />,
      completed: <PlanTypeBlackIcon />
    },
    4: {
      default: <PaymentInfoGreyIcon />,
      active: <PaymentInfoWhiteIcon />,
      completed: null
    }
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }}>
      {completed
        ? icons[String(props.icon)].completed
        : active
          ? icons[String(props.icon)].active
          : icons[String(props.icon)].default}
    </ColorlibStepIconRoot>
  );
}
