import { Box, Container, Grid, Typography } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import { useQuery } from 'react-query';
import { blog } from './APIRequests.js';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageLoader from '../../components/PageLoader.js';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import ServerError from '../Static/ServerError.js';

export default function Blog() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.blog.name);

  const { id } = useParams();

  const { data, isLoading, error } = useQuery(['blog', id], () => blog(id), {
    enabled: !!id // Ensure the query runs only if id is available
  });

  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/blogs/${id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const titleStyles = {
    fontFamily: 'Urbanist',
    fontSize: '25px',
    fontWeight: 600,
    lineHeight: '30px',
    width: isMobile ? '100%' : '80%',
    marginBottom: '20px'
  };

  const recentTitleStyles = {
    fontFamily: 'Urbanist',
    fontSize: '25px',
    fontWeight: 600,
    lineHeight: '30px',
    marginBottom: '20px'
  };

  const recentPostTitleStyles = {
    fontFamily: 'Inter',
    fontSize: '21px',
    fontWeight: 600,
    lineWeight: '25px'
  };

  const imageBoxStyles = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: isMobile ? '100%' : isTablet ? '400px' : '100%',
    height: isMobile ? '250px' : isTablet ? '270px' : '550px',
    borderRadius: '10px'
  });

  const recentPostImageBoxStyles = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: isMobile ? '100%' : isTablet ? '400px' : '100%',
    height: isMobile ? '250px' : isTablet ? '270px' : '300px',
    borderRadius: '10px'
  });

  const authorStyles = {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '20px',
    marginTop: '30px'
  };

  return (
    <Fragment>
      {isLoading && <PageLoader />}
      {!isLoading && error && <ServerError />}
      {!isLoading && !error && (
        <Container maxWidth="xl">
          <Grid pt={12} pb={6} container columnSpacing={15} rowSpacing={{ xs: 5, md: 0 }}>
            <Grid item md={8} xs={12}>
              <Typography sx={titleStyles}>{data?.data.title}</Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Box sx={imageBoxStyles(process.env.REACT_APP_BACKEND_URL + data?.data.image)}></Box>
              <Typography sx={authorStyles} style={{ color: theme.palette.primary.main }}>
                {data?.data.author} • {data?.data.date}
              </Typography>
              <Typography
                variant="body"
                pb={2}
                dangerouslySetInnerHTML={{
                  __html: data?.data.body
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={recentTitleStyles}>Recent Post</Typography>
              <Box sx={{ cursor: 'pointer' }} onClick={() => handleClick(data?.recent_post.id)}>
                <Box
                  sx={recentPostImageBoxStyles(
                    process.env.REACT_APP_BACKEND_URL + data?.recent_post.image
                  )}></Box>
                <Typography sx={recentPostTitleStyles} my={2}>
                  {data?.recent_post.title}
                </Typography>
                <Typography
                  variant="body"
                  dangerouslySetInnerHTML={{
                    __html: data?.recent_post.body
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </Fragment>
  );
}
