import * as Yup from 'yup';
import moment from 'moment';

export const validationSchema4 = Yup.object().shape({
  address: Yup.string()
    .trim()
    .min(2, 'Address must be minimum 2 characters')
    .max(60, 'Address must not be more than 60 characters')
    .required('Address is required'),
  city: Yup.string().trim().required('City is required'),
  country: Yup.object({
    name: Yup.string(),
    id: Yup.string()
  }).required('Country is required'),
  state: Yup.string().trim().required('State is required'),
  zipCode: Yup.string().trim().required('Zip Code is required'),
  nameOnCard: Yup.string().trim().required('Name on Card is required'),
  cardNumber: Yup.string().trim().required('Card number is required'),
  expirationDate: Yup.string()
    .trim()
    .test('expirationDate', 'Expiration Date must be in the future', function (value) {
      if (!value) return false;

      const today = moment();
      const [month, day, year] = value.split('/');
      const expiration = moment(`${year}-${month}`, 'YYYY-MM').endOf('month');

      return expiration.isAfter(today, 'day');
    })
    .required('Expiration Date is required'),
  securityCode: Yup.string().trim().max(4).required('Security Code is required'),
  discountCode: Yup.string().trim(),
  agreeToConditions: Yup.boolean().required(
    'You have to agree to terms & conditions and privacy policy'
  )
});
