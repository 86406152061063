import axios from 'axios';

export const getContactDetails = async () => {
  const response = await axios.get('contact_us');

  return response?.data;
};

export const submitContactForm = async (data) => {
  const response = await axios.post('contact_us/submit', data);

  return response?.data;
};
