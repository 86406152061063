import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useQuery } from 'react-query';
import { services } from './APIRequests.js';
import Facility from '../../components/Facility.js';
import PageLoader from '../../components/PageLoader.js';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import ServerError from '../Static/ServerError.js';

export default function Services() {
  const { data, isLoading, error } = useQuery('services', services);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.services.name);

  return (
    <>
      {isLoading && <PageLoader />}
      {!isLoading && error && <ServerError />}
      {!isLoading && !error && (
        <Container maxWidth="xl">
          <Grid pt={12} pb={5} align="center">
            <Typography variant="heading1"> {data?.title}</Typography>
            <Typography
              variant="subtitle"
              paragraph={true}
              dangerouslySetInnerHTML={{
                __html: data?.body
              }}
            />
            <Grid container pt={10} align="left" columnSpacing={5}>
              {data.services?.map((service) => (
                <Grid key={service.id} item md={6} xs={12}>
                  <Facility
                    img={process.env.REACT_APP_BACKEND_URL + service.image}
                    name={service.title}
                    description={service.text}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
