import { Grid, Typography, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { privacy } from './APIRequests.js';
import PageLoader from '../../components/PageLoader.js';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import ServerError from './ServerError.js';

export default function Privacy() {
  const { data, isLoading, error } = useQuery('privacy', privacy);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.privacy.name);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading && <PageLoader />}
      {!isLoading && error && <ServerError />}
      {!isLoading && !error && (
        <Container maxWidth="xl">
          <Grid container spacing={2} pt={12} pb={5}>
            <Grid item xs={12} md={8} sx={{ margin: '0 auto' }}>
              <Typography variant="heading2"> {data?.title}</Typography>
              <Typography
                variant="static_content"
                dangerouslySetInnerHTML={{
                  __html: data?.body
                }}
                sx={{ textAlign: 'justify' }}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
