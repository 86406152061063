import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Grid,
  Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '../assets/logout.svg';

const LogoutConfirmationDialog = ({ open, handleClose, handleLogout }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ py: 5, position: 'relative' }}>
        <IconButton
          onClick={handleClose}
          size="small"
          sx={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <Grid container>
            <Grid item width={'100%'} textAlign={'center'}>
              <img src={LogoutIcon} alt="logout" />
            </Grid>
            <Grid item width={'100%'} textAlign={'center'}>
              <Typography paragraph={true} fontWeight={600} fontSize={20} mb={0}>
                Are you sure you want to log out?
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box textAlign={'center'}>
            <Typography textAlign={'center'} variant="body">
              Come back soon, we are waiting for you.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={handleClose} variant="outlined" sx={{ width: '140px' }}>
            Cancel
          </Button>
          <Button onClick={handleLogout} variant="contained" sx={{ width: '140px' }}>
            Log out
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default LogoutConfirmationDialog;
