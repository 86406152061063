import { Card, Grid, Typography } from "@mui/material";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

function Service({ icon, text }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const cardStyles = {
    borderRadius: "10px",
    boxShadow: "none",
    px: 3,
    pb: 3,
    py: isMobile ? 3 : 5,
    backgroundColor: "rgba(92, 181, 162, 0.06)",
  };

  const gridStyles = { fontSize: "35px", color: "#FFC27A" };

  const textStyles = {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    color: "#10182F",
    minHeight: isMobile ? "unset" : "150px",
    "& p": {
      mt: isMobile ? "8px" : "16px",
    },
  };
  
  return (
    <Card sx={cardStyles}>
      <Grid sx={gridStyles}>
        <img src={icon} alt="" style={{ width: isMobile ? "50px" : "unset" }} />
        <Typography
          sx={textStyles}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Grid>
    </Card>
  );
}

export default Service;
