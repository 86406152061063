import { validationSchema1 } from './step1.scheme';

const step1FormikConfig = (customLabel) => ({
  initialValues: {
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    professionalSuffix: '',
    permanentLegalResidence: '',
    customLabel: '',
    phoneNumber: '',
    dateOfBirth: '',
    address: '',
    address2: '',
    city: '',
    country: '',
    state: '',
    zipCode: '',
    email: '',
    confirmEmailAddress: '',
    countryDialCode: '',
    countryIsoCode: ''
  },
  validationSchema: () => validationSchema1(customLabel),
  onSubmit: async () => {},
  enableReinitialize: true
});

export default step1FormikConfig;
