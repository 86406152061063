import * as React from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import Service from './Service';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { MAX_NB_OF_SERVICES_CARDS_PER_ROW } from '../../utils/constants';

export default function Services({ title, description, list, button }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust breakpoint as needed

  return (
    <Grid py={5} sx={{ backgroundColor: '#FAFAFA' }} align="center">
      <Container maxWidth="xl">
        <Typography
          variant="heading2"
          sx={{
            mb: 1
          }}
          dangerouslySetInnerHTML={{ __html: title }}
        />

        <Typography
          variant="subtitle"
          paragraph={true}
          sx={{ m: 2 }}
          dangerouslySetInnerHTML={{ __html: description }}
        />

        <Grid container px={{ xs: 0, md: 5 }} pr={{ xs: 0, md: 5 }} pb={5}>
          {list?.slice(0, isMobile ? 3 : 6).map((service, index) => {
            const showBorderBottom = isMobile && index !== list.length - 4;

            const showBorderTop = !isMobile && index + 1 > MAX_NB_OF_SERVICES_CARDS_PER_ROW;

            const showBorderSideWays =
              !isMobile && (index + 1) % MAX_NB_OF_SERVICES_CARDS_PER_ROW === 2;

            return (
              <Grid
                key={service.id}
                item
                md={4}
                xs={12}
                borderBottom={showBorderBottom ? '1px solid #E4E4E7' : 'none'}
                borderTop={showBorderTop ? '1px solid #E4E4E7' : 'none'}
                borderRight={showBorderSideWays ? '1px solid #E4E4E7' : 'none'}
                borderLeft={showBorderSideWays ? '1px solid #E4E4E7' : 'none'}>
                <Service
                  img={process.env.REACT_APP_BACKEND_URL + service.image}
                  name={service.title}
                  description={service.text}
                />
              </Grid>
            );
          })}
        </Grid>
        <Button
          variant="contained"
          href="/services"
          sx={{
            color: '#fff',
            textTransform: 'none'
          }}>
          {button}
        </Button>
      </Container>
    </Grid>
  );
}
