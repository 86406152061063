import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../../../utils/colors";
import { SummarySectionContainer } from "../Wizard.styles";
import { ReactComponent as EditIcon } from "../../../assets/ic-edit.svg";

export default function SummaryStep({
  title,
  children,
  mt = 0,
  handleEditStep,
  stepIndex,
}) {
  return (
    <SummarySectionContainer mt={mt}>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="body" color={COLORS.black} fontWeight={700}>
          {title}
        </Typography>
        <IconButton onClick={() => handleEditStep(stepIndex)}>
          <EditIcon />
        </IconButton>
      </Grid>
      <Grid>{children}</Grid>
    </SummarySectionContainer>
  );
}
