import React from 'react';
import { useField } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { Typography } from '@mui/material';

const RecaptchaField = ({ name, onChange }) => {
  const [, meta, helpers] = useField(name);

  const handleRecaptchaChange = (value) => {
    helpers.setValue(value);

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        onChange={handleRecaptchaChange}
      />
      {meta.touched && meta.error && (
        <Typography sx={{ color: '#E8616B', marginTop: 1 }}>{meta.error}</Typography>
      )}
    </div>
  );
};

export default RecaptchaField;
