import { Box, Container, Grid, Typography } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import { useQuery } from 'react-query';
import { petAssistance } from './APIRequests.js';
import PageLoader from '../../components/PageLoader.js';
import Checklist from '../../components/Checklist.js';
import OtherServices from '../../components/OtherServices.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import styled from 'styled-components';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import ServerError from '../Static/ServerError.js';

const StyledTable = styled.div`
  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid rgba(185, 185, 185, 1);
    border-bottom: none;
  }
  th,
  td {
    border-bottom: 1px solid rgba(185, 185, 185, 1);
    border-right: 1px solid rgba(185, 185, 185, 1);
    padding: 10px 12px;
    color: rgba(0, 0, 0, 1);
    width: 50%;
  }
  th:last-child,
  td:last-child {
    border-right: none;
  }
  thead {
    background: rgba(0, 0, 0, 0.06);
    text-align: left;
  }
  p {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #5a6475;
  }
`;

export default function PetAssistance() {
  const { data, isLoading, error } = useQuery('petAssistance', petAssistance);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const imageBoxStyles = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: isMobile ? '250px' : isTablet ? '350px' : '100%',
    borderRadius: '10px'
  });

  const titleStyles = {
    fontFamily: 'Urbanist',
    fontWeight: 700,
    fontSize: '20px'
  };

  const servicesTitleStyles = {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px'
  };

  const otherServicesTitleStyles = {
    fontFamily: 'Urbanist',
    fontWeight: 700,
    fontSize: '20px'
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.servicesPetAssistance.name);

  return (
    <Fragment>
      {isLoading && <PageLoader />}
      {!isLoading && error && <ServerError />}
      {!isLoading && !error && (
        <Container maxWidth="xl">
          <Grid pt={12} pb={5} align="center">
            <Typography variant="heading1"> {data?.title}</Typography>
            <Typography
              variant="body"
              dangerouslySetInnerHTML={{
                __html: data?.body
              }}
            />
          </Grid>
          <Grid container columnSpacing={10} mb={5}>
            <Grid item md={5} xs={12} mb={{ xs: 2, md: 0 }}>
              <Box
                sx={imageBoxStyles(
                  process.env.REACT_APP_BACKEND_URL + data?.paragraphs.introduction.image
                )}></Box>
            </Grid>
            <Grid item md={7} xs={12}>
              <Typography sx={titleStyles}>{data?.paragraphs.introduction.title}</Typography>

              <StyledTable
                variant="body"
                dangerouslySetInnerHTML={{
                  __html: data?.paragraphs.introduction.body
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={7} xs={12}>
              <Typography
                sx={servicesTitleStyles}
                dangerouslySetInnerHTML={{ __html: data?.paragraphs.text.body }}
              />
            </Grid>
            <Grid item md={7} xs={12} mb={5}>
              <Grid container>
                {data?.paragraphs.multiple_title_text?.map((service) => {
                  const { id, title, body } = service;

                  return (
                    <Grid key={id} item mt={2}>
                      <Checklist name={title} description={body} />
                    </Grid>
                  );
                })}
              </Grid>
              <Typography sx={otherServicesTitleStyles} mt={10} mb={2}>
                {data?.paragraphs.services.title}
              </Typography>
              <OtherServices list={data?.paragraphs.services.services} />
            </Grid>
          </Grid>
        </Container>
      )}
    </Fragment>
  );
}
