import { Box, Container, Grid, Typography } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import { useQuery } from 'react-query';
import { extendedTravelProgram } from './APIRequests.js';
import PageLoader from '../../components/PageLoader.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import OtherServices from '../../components/OtherServices.js';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import ServerError from '../Static/ServerError.js';

export default function ExtendedTravelProgram() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data, isLoading, error } = useQuery('extendedTravelProgram', extendedTravelProgram);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.extendedTravelProgram.name);

  const imageBoxStyles = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: isMobile ? '250px' : isTablet ? '400px' : '500px',
    borderRadius: '10px'
  });

  const titleStyles = {
    fontFamily: 'Urbanist',
    fontWeight: 700,
    fontSize: '20px'
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      {isLoading && <PageLoader />}
      {!isLoading && error && <ServerError />}
      {!isLoading && !error && (
        <Container maxWidth="xl">
          <Grid pt={12} pb={{ xs: 0, md: 5 }} align="center">
            <Typography variant="heading1"> {data?.title}</Typography>
          </Grid>
          <Grid container columnSpacing={{ sm: 5, lg: 12 }} mt={5} mb={10}>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Box
                sx={imageBoxStyles(
                  process.env.REACT_APP_BACKEND_URL + data?.paragraphs.introduction.image
                )}></Box>
            </Grid>
            <Grid item lg={8} md={8} sm={6} xs={12} mt={{ xs: 2, md: 0 }}>
              <Typography sx={titleStyles}>{data?.paragraphs.introduction.title}</Typography>
              <Typography
                variant="body"
                dangerouslySetInnerHTML={{
                  __html: data?.paragraphs.introduction.body
                }}
              />
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Typography sx={titleStyles} mt={10} mb={2}>
                  {data?.paragraphs.services.title}
                </Typography>
                <OtherServices list={data?.paragraphs.services.services} />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: { xs: 'block', md: 'none' } }} mb={5}>
            <Typography sx={titleStyles} mt={10} mb={2}>
              {data?.paragraphs.services.title}
            </Typography>
            <OtherServices list={data?.paragraphs.services.services} />
          </Box>
        </Container>
      )}
    </Fragment>
  );
}
