import {
  Box,
  Container,
  Grid,
  Typography,
  ListItemText,
  ListItem,
  ListItemIcon,
  Button,
  TextField,
  FormHelperText,
  TextareaAutosize
} from '@mui/material';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
import { getContactDetails, submitContactForm } from './APIRequests.js';
import PageLoader from '../../components/PageLoader.js';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CustomAlert from '../../components/CustomAlert.js';
import { TextInput } from '../../components/form/TextField';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-input-material-ui/lib/style.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import RecaptchaField from '../../components/RecaptchaField.js';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import ServerError from '../Static/ServerError.js';

export default function ContactUs() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { data, isLoading, error } = useQuery('contact', getContactDetails);
  const mutation = useMutation(submitContactForm);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.contactUs.name);

  const titleStyles = {
    fontFamily: 'Urbanist',
    fontSize: isMobile ? '35px' : '40px',
    fontWeight: isMobile ? 600 : 800,
    lineHeight: '48px'
  };

  const textAreaInputProps = {
    inputComponent: TextareaAutosize,
    inputProps: {
      minRows: 5,
      maxLength: 512
    },
    style: {
      maxHeight: '150px',
      overflow: 'auto',
    }
  };

  const imageBoxStyles = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    width: '100%',
    height: '100%',
    position: 'relative',
    borderRadius: '10px',
    display: { xs: 'none', md: 'flex' }
  });

  const textBoxStyles = {
    position: 'absolute',
    bottom: '40px',
    color: 'white',
    left: '40px',
    width: '50%'
  };

  const mobileTextBoxStyles = {
    display: { xs: 'block', md: 'none' }
  };

  const contactTextStyles = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: isMobile ? 400 : 200
  };

  const contactIconStyles = {
    color: isMobile ? '#5CB5A2' : 'white',
    minWidth: '35px'
  };

  const formBoxStyles = {
    width: isMobile ? '100%' : '70%'
  };

  const [isAlertOpened, setIsAlertOpened] = useState(false);

  const [alertContent, setAlertContent] = useState({
    severity: '',
    title: '',
    body: ''
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .min(2, 'Name is too short')
      .max(50, 'Name must not be more than 50 characters'),
    phone: Yup.string()
      .required('Phone number is required')
      .test('is-valid-phone', 'Phone number is not valid', (value) => {
        const phoneWithCountryCode = value.startsWith('+') ? value : `+${value}`;
        const phoneNumber = parsePhoneNumberFromString(phoneWithCountryCode);

        return phoneNumber ? phoneNumber.isValid() : false;
      }),
    email: Yup.string().required('Email is required').email('Invalid email format'),
    message: Yup.string().required('Message is required').min(10, 'Message is too short'),
    recaptcha: Yup.string().required('Please complete the reCAPTCHA'),
    //extras not shown on the UI, but needed
    countryDialCode: Yup.string(),
    countryIsoCode: Yup.string()
  });

  const googleMapsLink = `https://maps.app.goo.gl/ssuRB2hNnzTgkyJR9`;

  return (
    <>
      {isLoading && <PageLoader />}
      {!isLoading && error && <ServerError />}
      {!isLoading && !error && (
        <Container maxWidth="xl">
          <Grid container columnSpacing={15} pt={12} pb={6}>
            <Grid item xs={12} md={6}>
              <Box sx={imageBoxStyles(process.env.REACT_APP_BACKEND_URL + data?.background_image)}>
                <Box sx={textBoxStyles}>
                  <Typography variant="more_info_text">For More Information</Typography>
                  <ListItem
                    component="a"
                    href={googleMapsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: 'inherit' }}>
                    <ListItemIcon sx={contactIconStyles}>
                      <LocationOnOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      sx={contactTextStyles}
                      primary={data?.address}
                    />
                  </ListItem>
                  <ListItem component="a" href={`tel:${data?.phone}`} sx={{ color: 'inherit' }}>
                    <ListItemIcon sx={contactIconStyles}>
                      <PhoneOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText disableTypography sx={contactTextStyles} primary={data?.phone} />
                  </ListItem>
                  <ListItem component="a" href={`mailto:${data?.email}`} sx={{ color: 'inherit' }}>
                    <ListItemIcon sx={contactIconStyles}>
                      <EmailOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText disableTypography sx={contactTextStyles} primary={data?.email} />
                  </ListItem>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <CustomAlert
                severity={alertContent.severity}
                title={alertContent.title}
                body={alertContent.body}
                isOpened={isAlertOpened}
                onAlertClose={() => setIsAlertOpened(false)}
              />
              <Box sx={formBoxStyles}>
                <Typography sx={titleStyles} paragraph={true}>
                  Contact Us
                </Typography>
                <Typography variant="body" paragraph={true} sx={{ paddingBottom: 3 }}>
                  {data?.message}
                </Typography>
                <Formik
                  initialValues={{
                    name: '',
                    phone: '',
                    email: '',
                    message: '',
                    recaptcha: ''
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    mutation.mutate(values, {
                      onSuccess: () => {
                        setSubmitting(false);

                        setAlertContent({
                          severity: 'success',
                          title: 'Sent Successfully',
                          body: 'Your message has been sent successfully!'
                        });

                        setIsAlertOpened(true);
                        resetForm();
                        window.scrollTo(0, 80);
                      },
                      onError: () => {
                        setSubmitting(false);

                        setAlertContent({
                          severity: 'error',
                          title: 'Failed to send',
                          body: 'Your message has not been sent, please try again'
                        });

                        setIsAlertOpened(true);
                        window.scrollTo(0, 80);
                      }
                    });
                  }}>
                  {({
                    isSubmitting,
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue
                  }) => (
                    <Form>
                      <TextInput
                        htmlFor="Name"
                        name="name"
                        label="Your Name"
                        title="Your Name"
                        variant="filled"
                        error={touched?.name && Boolean(errors?.name)}
                        helperText={touched?.name && errors?.name}
                        sx={{ marginBottom: 3 }}
                        value={values.name}
                        onChange={handleChange}
                      />
                      <TextInput
                        htmlFor="Email"
                        name="email"
                        label="Email"
                        title="Email"
                        variant="filled"
                        error={touched?.email && Boolean(errors?.email)}
                        helperText={touched?.email && errors?.email}
                        sx={{ marginBottom: 3 }}
                        value={values.email}
                        onChange={handleChange}
                        maxLength={60}
                      />
                      <ReactPhoneInput
                        enableSearchField={true}
                        label={''}
                        onChange={(value, country) => setFieldValue('phone', value)}
                        value={values.phone}
                        component={TextField}
                        placeholder={'8023456789'}
                        onBlur={handleBlur}
                        localization={'en'}
                        country={'us'}
                        autoFormat={true}
                        countryCodeEditable={true}
                        name="phone"
                        id="phone"
                        fullWidth
                        inputProps={{
                          variant: 'outlined',
                          name: 'phone',
                          id: 'phone',
                          required: true
                        }}
                      />
                      {touched?.phone && <FormHelperText>{errors.phone}</FormHelperText>}
                      <TextInput
                        htmlFor="Comment/Question"
                        name="message"
                        label="Comment/Question"
                        multiline={true}
                        title="Comment/Question"
                        variant="filled"
                        error={touched?.message && Boolean(errors?.message)}
                        helperText={touched?.message && errors?.message}
                        sx={{ my: 3 }}
                        value={values.message}
                        onChange={handleChange}
                        InputProps={textAreaInputProps}
                      />
                      <RecaptchaField name="recaptcha" />

                      <Button
                        fullWidth={true}
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                        sx={{ marginTop: 2 }}
                        endIcon={
                          isSubmitting ? (
                            <CircularProgress size={20} sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />
                          ) : null
                        }>
                        Send Message
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Box>
              <Box sx={mobileTextBoxStyles}>
                <Typography variant="more_info_text" paragraph={true}>
                  For More Information
                </Typography>
                <ListItem>
                  <ListItemIcon sx={contactIconStyles}>
                    <LocationOnOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText disableTypography sx={contactTextStyles} primary={data?.address} />
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={contactIconStyles}>
                    <PhoneOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText disableTypography sx={contactTextStyles} primary={data?.phone} />
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={contactIconStyles}>
                    <EmailOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText disableTypography sx={contactTextStyles} primary={data?.email} />
                </ListItem>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
