import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import OtpInput from 'react-otp-input';
import { generateOTP } from '../../pages/Account/APIRequests.js';
import CircularProgress from '@mui/material/CircularProgress';

function OTPLogin({
  handlePasswordLogin,
  values,
  touched,
  errors,
  isSubmitting,
  setFieldValue,
  email,
  setAlertContent,
  setIsAlertOpened
}) {
  const [timer, setTimer] = useState(60);
  const [resendOTPEnabled, setResendOTPEnabled] = useState(false);
  const [activeInput, setActiveInput] = useState(null);

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    } else {
      setResendOTPEnabled(true);
    }
  }, [timer]);

  const handleResendOTP = () => {
    const body = { email: email };

    generateOTP(body)
      .then((data) => {
        setAlertContent({
          severity: 'success',
          title: 'Sent Successfully',
          body: 'OTP has been sent to your number ending in ' + data?.phone_number
        });

        setTimer(60);
        setResendOTPEnabled(false);
      })
      .catch((error) => {
        setAlertContent({
          severity: 'error',
          title: 'Error in sending OTP',
          body: 'Please try again'
        });
      });

    setIsAlertOpened(true);
  };

  const verifyEndIcon = isSubmitting ? (
    <CircularProgress size={20} sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />
  ) : null;

  const inputStyle = {
    width: '2.5rem',
    height: '2.5rem',
    margin: '0.5rem 1rem 0 0',
    textAlign: 'center',
    marginTop: '5px',
    border: '1px solid #CFD3D4',
    fontSize: '18px',
    borderRadius: '8px',
    outline: 'none'
  };

  const activeStyle = {
    border: '1px solid #5CB5A2'
  };

  return (
    <Fragment>
      <Typography sx={{ fontFamily: 'Inter' }}>OTP</Typography>
      <OtpInput
        value={values.otp}
        onChange={(otp) => setFieldValue('otp', otp)}
        numInputs={4}
        inputType={'tel'}
        renderInput={(props, index) => (
          <input
            {...props}
            style={{
              ...inputStyle,
              ...(activeInput === index ? activeStyle : {})
            }}
            onFocus={() => setActiveInput(index)}
            onBlur={() => setActiveInput(null)}
          />
        )}
        containerStyle={{ justifyContent: 'start' }}
      />
      {errors.otp && touched.otp && (
        <Typography sx={{ color: '#E8616B', marginTop: 1 }}>{errors.otp}</Typography>
      )}

      <Button
        fullWidth={true}
        sx={{ marginTop: 3 }}
        variant="contained"
        type="submit"
        disabled={isSubmitting}
        endIcon={verifyEndIcon}>
        Verify
      </Button>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Button disableRipple variant="text" onClick={handlePasswordLogin}>
          Log in using password
        </Button>
        {resendOTPEnabled ? (
          <Button
            disableRipple
            variant="text"
            sx={{ color: 'rgba(16, 24, 47, 0.5)' }}
            onClick={handleResendOTP}>
            Resend
          </Button>
        ) : (
          <Typography variant="body" fontSize={'14px'} mt={1}>
            Resend in {timer}s
          </Typography>
        )}
      </Box>
    </Fragment>
  );
}

export default OTPLogin;
