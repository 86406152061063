import { Grid } from '@mui/material';
import React from 'react';
import Plan from '../../../components/Plan';

export default function PlansGrid({ list, onPlanClick }) {
  return (
    <Grid container gap={3} align="center" justifyContent="center">
      {list?.length > 0 &&
        list?.map((plan) => {
          const { id, plan: planName, text, price } = plan;

          return (
            <Grid item xl={3.5} sm={8} key={plan.id} mb={2}>
              <Plan
                name={planName}
                description={text}
                price={price}
                hideStartButton={false}
                hoverEffect={true}
                onStartPlanClick={() => onPlanClick(id)}
              />
            </Grid>
          );
        })}
    </Grid>
  );
}
