import React from 'react';
import { Grid, Typography } from '@mui/material';
import { COLORS } from '../../../utils/colors';
import { ReactComponent as ArtDoneChanges } from '../../../assets/art-done-changes.svg';

const NoPayment = () => {
  return (
    <Grid mb={3}>
      <ArtDoneChanges />
      <Grid mt={2.375} mb={0.8}>
        <Grid mb={2}>
          <Typography variant="bodyTitle" fontWeight={700} color={COLORS.darkBlack}>
            Payment info
          </Typography>
        </Grid>
        <Typography variant="body" fontWeight={400}>
          No additional payment is required for the changes you have made.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoPayment;
