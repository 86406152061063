import axios from 'axios';

export const getWelcomePage = async (token) => {
  const response = await axios.get(`customer-welcome-page/${token}`, {
    auth: {
      username: process.env.REACT_APP_BASIC_AUTH,
      password: process.env.REACT_APP_BASIC_AUTH_PASS
    }
  });

  return response.data;
};
