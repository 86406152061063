import React, { Fragment } from 'react';
import PageLoader from '../../components/PageLoader.js';
import { getCoverageLetter, getCoverageLetterPDF } from './APIRequests.js';
import { Box, Container, Grid, Typography, Paper } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useQuery } from 'react-query';
import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PDFIcon from '../../assets/pdf.svg';
import { saveAs } from 'file-saver';
import { GOOGLE_ANALYTICS_LABELS, sendEvent } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import ServerError from '../Static/ServerError.js';

export default function CoverageLetter() {
  const { data, isLoading, error } = useQuery('getCoverageLetter', getCoverageLetter);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.coverageLetter.name);

  const handleDocumentDownload = async (id, name) => {
    try {
      const blob = await getCoverageLetterPDF(id);
      saveAs(blob, name);
      sendEvent('Download', 'Coverage_Letter', 1);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const handleDocumentOpen = async (id) => {
    try {
      const blob = await getCoverageLetterPDF(id);
      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const CustomScrollbarBox = styled(Box)({
    width: '100%',
    maxWidth: 700,
    height: isMobile ? 'unset' : '450px',
    overflow: 'auto',
    marginBottom: '70px',
    marginTop: '70px',

    '&::-webkit-scrollbar': {
      width: 6
    },

    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#CCCBCB',
      borderRadius: '10px'
    }
  });

  const DocumentItem = ({ data, current }) => (
    <Paper
      variant="outlined"
      sx={{
        px: 4,
        py: 2,
        marginBottom: 2,
        borderRadius: '10px',
        backgroundColor: current ? '#FFF' : '#F8F8F8'
      }}>
      <Grid container>
        <Grid item sm={12} md={11} width={'100%'}>
          <Grid container justifyContent={'space-between'}>
            <Grid item sm={1} mt={{ xs: 0, md: 1 }}>
              <img src={PDFIcon} alt="PDF Icon" style={{ marginRight: 16 }} />
            </Grid>
            <Grid item md={1} sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton
                sx={{
                  border: '1px solid #5CB5A2',
                  color: '#5CB5A2'
                }}
                onClick={() => handleDocumentDownload(data?.nid, data?.coverage_letter_filename)}>
                <ArrowDownwardIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Grid>
            <Grid item xs={12} md={10} textAlign={'left'}>
              <Typography
                fontWeight={500}
                fontSize={20}
                fontFamily={'Inter'}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleDocumentOpen(data?.nid)}>
                {data?.coverage_letter_filename}
              </Typography>
              <Typography variant="body">
                {data?.plan} • Expiry Date: {data?.end_date}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={1} sx={{ display: { xs: 'none', md: 'block' } }}>
          <IconButton
            sx={{
              border: '1px solid #5CB5A2',
              color: '#5CB5A2'
            }}
            onClick={() => handleDocumentDownload(data?.nid, data?.coverage_letter_filename)}>
            <ArrowDownwardIcon sx={{ fontSize: 20 }} />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );

  const DocumentList = () => (
    <CustomScrollbarBox>
      {data?.active_policies.length > 0 && (
        <>
          <Typography
            fontWeight={600}
            fontFamily={'Urbanist'}
            fontSize={20}
            textAlign={'left'}
            mb={1}>
            Active Coverage Letter
          </Typography>
          {data?.active_policies.map((doc, index) => (
            <DocumentItem key={index} data={doc} current={true} />
          ))}
        </>
      )}
      {data?.future_policies.length > 0 && (
        <>
          <Typography
            fontWeight={600}
            fontFamily={'Urbanist'}
            fontSize={20}
            textAlign={'left'}
            mb={1}>
            Future Coverage Letter
          </Typography>
          {data?.future_policies.map((doc, index) => (
            <DocumentItem key={index} data={doc} />
          ))}
        </>
      )}
      {data?.previous_policies.length > 0 && (
        <>
          <Typography
            fontWeight={600}
            fontFamily={'Urbanist'}
            fontSize={20}
            textAlign={'left'}
            mb={1}>
            Previous Coverage Letters
          </Typography>
          {data?.previous_policies.map((doc, index) => (
            <DocumentItem key={index} data={doc} />
          ))}
        </>
      )}
    </CustomScrollbarBox>
  );

  return (
    <Fragment>
      {isLoading && <PageLoader />}
      {!isLoading && error && <ServerError />}
      {!isLoading && !error && (
        <Container maxWidth="xl">
          <Grid pt={12} align="center">
            <>
              <Typography variant="heading1">{data?.page_data.title}</Typography>
              <Typography
                mt={3}
                variant="subtitle"
                paragraph={true}
                dangerouslySetInnerHTML={{
                  __html: data?.page_data.subtitle
                }}
              />
              <DocumentList />
            </>
          </Grid>
        </Container>
      )}
    </Fragment>
  );
}
