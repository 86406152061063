import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useQuery } from 'react-query';
import { why_choose } from './APIRequests.js';
import Checklist from '../../components/Checklist.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Feedback from '../../components/whychoose/Feedback.js';
import PageLoader from '../../components/PageLoader.js';
import StyledGrid from '../../components/whychoose/StyledGrid.js';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import { checkSessionStorageItem } from '../../utils/sessionStorage.js';
import { SESSION_STORAGE_CLIENT } from '../../utils/constants.js';
import ServerError from '../Static/ServerError.js';

export default function WhyChoose() {
  const { data, isLoading, error } = useQuery('why_choose', why_choose);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);
  const isLoggedIn = sessionStorage.getItem('isLoggedIn');

  const navigateTo = hasClient ? `${client}/plans` : `/plans`;

  const imageBoxStyles = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '600px',
    borderRadius: '10px'
  });

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.whyChoose.name);

  return (
    <>
      {isLoading && <PageLoader />}
      {!isLoading && error && <ServerError />}
      {!isLoading && !error && (
        <Container maxWidth="xl">
          <Grid pt={12} mb={{ md: 10, xs: 5 }} align="center">
            <Typography variant="heading1"> {data?.title} </Typography>
          </Grid>
          <Grid container columnSpacing={5}>
            <Grid item md={8} xs={12}>
              <Grid container columnSpacing={5} pb={4} rowSpacing={2} sx={{ display: 'flex' }}>
                {data?.paragraphs.feedback.map((feedback) => (
                  <Grid item md={4} xs={12} key={feedback.id} sx={{ display: 'flex' }}>
                    <Feedback text={feedback.text} />
                  </Grid>
                ))}
              </Grid>
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px'
                }}
                dangerouslySetInnerHTML={{
                  __html: data?.paragraphs.introduction.body
                }}
              />
            </Grid>
            <Grid item md={4} sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Box
                sx={imageBoxStyles(
                  process.env.REACT_APP_BACKEND_URL + data?.paragraphs.introduction.image
                )}></Box>
            </Grid>
          </Grid>
          <Grid mt={{ md: 10, xs: 5 }}>
            <Typography
              sx={{ fontFamily: 'Inter', width: '80%' }}
              dangerouslySetInnerHTML={{
                __html: data?.paragraphs.highlights.body
              }}
            />
            <Grid container mt={5} columnSpacing={4}>
              {data?.paragraphs.highlights.features?.map((feature) => (
                <Grid key={feature.id} item md={6} mb={3}>
                  <Checklist name={feature.title} description={feature.body} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          {!isLoggedIn && (
            <StyledGrid isMobile={isMobile}>
              <Typography
                sx={{ fontFamily: 'Inter', width: isMobile ? '100%' : '80%' }}
                dangerouslySetInnerHTML={{
                  __html: data?.paragraphs.message_with_button.body
                }}
              />

              <Button
                align="center"
                variant="outlined"
                sx={{
                  color: '#fff',
                  border: '1px solid #fff',
                  '&:hover': {
                    border: '1px solid #fff'
                  }
                }}
                href={navigateTo}>
                {data?.paragraphs.message_with_button.button_text}
              </Button>
            </StyledGrid>
          )}
        </Container>
      )}
    </>
  );
}
