import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useQuery } from 'react-query';
import { faq } from './APIRequests.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PageLoader from '../../components/PageLoader.js';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import ServerError from './ServerError.js';

export default function Faq() {
  const { data, isLoading, error } = useQuery('faq', faq);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.faq.name);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const imageBoxStyles = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: isMobile ? '100px' : '250px'
  });

  const overlayStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#0000004D'
  };

  const questionStyles = {
    boxShadow: 'none',
    fontFamily: 'Inter',
    fontSize: isMobile ? '18px' : '20px',
    fontWeight: 500,
    py: 2
  };

  const answerStyles = {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    color: '#5A6475'
  };

  const titleStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fff'
  };

  return (
    <>
      {isLoading && <PageLoader />}
      {!isLoading && error && <ServerError />}
      {!isLoading && !error && (
        <>
          <Grid container pt={8} pb={5}>
            <Grid sx={{ position: 'relative' }} item md={12} xs={12}>
              <Box sx={imageBoxStyles(process.env.REACT_APP_BACKEND_URL + data?.image)}>
                <Box sx={overlayStyles}></Box>
              </Box>
              <Typography variant="heading2" sx={titleStyles}>
                {data?.title}
              </Typography>
            </Grid>
          </Grid>

          <Container maxWidth="xl" sx={{ paddingBottom: 5 }}>
            {data?.paragraphs?.faq.map((q, i) => (
              <Accordion
                expanded={expanded === 'panel' + i}
                onChange={handleChange('panel' + i)}
                key={i}
                sx={questionStyles}>
                <AccordionSummary
                  expandIcon={
                    expanded === 'panel' + i ? (
                      <CloseIcon sx={{ color: '#5CB5A2' }} />
                    ) : (
                      <AddIcon sx={{ color: '#5CB5A2' }} />
                    )
                  }
                  aria-controls="panel3-content"
                  id="panel3-header">
                  {q.question}
                </AccordionSummary>
                <AccordionDetails sx={answerStyles}>
                  <Typography
                    variant="static_content"
                    dangerouslySetInnerHTML={{
                      __html: q.answer
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
        </>
      )}
    </>
  );
}
