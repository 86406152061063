import * as Yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const validationSchema1 = (customLabelValue) => {
  return Yup.object({
    title: Yup.object({
      id: Yup.string(),
      name: Yup.string()
    }),

    firstName: Yup.string()
      .trim()
      .min(2, 'First Name must be minimum 2 characters')
      .max(100, 'First Name must not be more than 30 characters')
      .required('First Name is required'),

    middleName: Yup.string()
      .trim()
      .min(2, 'Middle Name must be minimum 2 characters')
      .max(30, 'Middle Name  not be more than 30 characters'),

    lastName: Yup.string()
      .trim()
      .min(2, 'Last Name must be minimum 2 characters')
      .max(30, 'Last Name must not be more than 30 characters')
      .required('Last Name is required'),

    suffix: Yup.object({
      id: Yup.string(),
      name: Yup.string()
    }),

    professionalSuffix: Yup.object({
      id: Yup.string(),
      name: Yup.string()
    }),

    phoneNumber: Yup.string()
      .required('Phone number is required')
      .test('is-valid-phone', 'Phone number is not valid', (value) => {
        const phoneWithCountryCode = value.startsWith('+') ? value : `+${value}`;
        const phoneNumber = parsePhoneNumberFromString(phoneWithCountryCode);

        return phoneNumber ? phoneNumber.isValid() : false;
      }),

    dateOfBirth: Yup.date().required('Date is required'),

    permanentLegalResidence: Yup.string()
      .trim()
      .min(2, 'Permanent Legal Residence must be minimum 2 characters')
      .max(60, 'Permanent Legal Residence must not be more than 60 characters')
      .required('Permanent Legal Residence is required'),

    customLabel: customLabelValue
      ? Yup.string()
          .trim()
          .min(2, `${customLabelValue} must be minimum 2 characters`)
          .max(60, `${customLabelValue} must not be more than 60 characters`)
          .required(`${customLabelValue} is required.`)
      : Yup.string(),

    address: Yup.string()
      .trim()
      .min(2, 'Address must be minimum 2 characters')
      .max(60, 'Address must not be more than 60 characters')
      .required('Address is required'),
    address2: Yup.string()
      .trim()
      .min(2, 'Address must be minimum 2 characters')
      .max(60, 'Address must not be more than 60 characters'),
    city: Yup.string().trim().required('City is required'),
    country: Yup.object({
      name: Yup.string().required('Country is required'),
      id: Yup.string()
    }).required('Country is required'),

    state: Yup.string()
      .trim()
      .max(30, 'State must not be more than 30 characters')
      .required('State is required'),
    zipCode: Yup.string().trim().required('Zip Code is required'),
    email: Yup.string().email('Invalid email').required('Email Address is required'),
    confirmEmailAddress: Yup.string()
      .oneOf([Yup.ref('email'), null], 'Emails must match')
      .required('Confirm Email Address is required'),

    //extras not shown on the UI, but needed
    countryDialCode: Yup.string(),
    countryIsoCode: Yup.string()
  });
};
