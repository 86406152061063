import { Grid, Typography } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { COLORS } from '../utils/colors';

const primaryColors = {
  iconColor: '#5CB5A2',
  iconBackgroundColor: '#E8F2EE',
  textColor: COLORS.black,
  descriptionColor: COLORS.grey
};

const secondaryColors = {
  iconColor: 'white',
  iconBackgroundColor: '#FFFFFF33',
  textColor: 'white',
  descriptionColor: 'white'
};

const checkIconStyles = {
  borderRadius: '50%'
};

const mainContainerStyles = { flexWrap: 'nowrap', alignItems: 'flex-start' };

function Checklist({ name, description, colorVariant }) {
  const colors = colorVariant === 'secondary' ? secondaryColors : primaryColors;
  const textVariant = colorVariant === 'secondary' ? 'bodyNoJustify' : 'body';

  return (
    <Grid container sx={mainContainerStyles}>
      <Grid item>
        <CheckIcon
          sx={{
            ...checkIconStyles,
            color: colors.iconColor,
            backgroundColor: colors.iconBackgroundColor
          }}
        />
      </Grid>
      <Grid item ml={2} display={'flex'} flexDirection={'column'}>
        <Typography variant={textVariant} color={colors.textColor} fontWeight={'500'}>
          {name}
        </Typography>
        {description !== undefined && (
          <Typography
            variant={textVariant}
            color={colors.descriptionColor}
            dangerouslySetInnerHTML={{
              __html: description
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default Checklist;
