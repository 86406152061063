import { Grid, Typography } from "@mui/material";
import React from "react";

function Service({ img, name, description }) {
  const textStyles = {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "24px",
    mt: 3,
  };

  return (
    <Grid py={{ md: 5, xs: 3 }} px={{ md: 5, xs: 0 }}>
      <img src={img} alt="" />
      <Typography paragraph={true} sx={textStyles}>
        {name}
      </Typography>
      <Typography
        variant="body"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Grid>
  );
}

export default Service;
