import axios from 'axios';
import { SESSION_STORAGE_ACCESS_TOKEN, SESSION_STORAGE_REFRESH_TOKEN } from './utils/constants';
import { clearUserInfo } from './utils/sessionStorage';

export const getAccessToken = () => {
  return sessionStorage.getItem(SESSION_STORAGE_ACCESS_TOKEN);
};

export const setAccessToken = (token) => {
  sessionStorage.setItem(SESSION_STORAGE_ACCESS_TOKEN, token);
};

export const refreshToken = async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}token/refresh`, {
      refresh_token: sessionStorage.getItem(SESSION_STORAGE_REFRESH_TOKEN)
    });

    return response.data;
  } catch (error) {
    clearUserInfo();
    window.location.href = '/login';
  }
};
