import { Grid, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../../../utils/colors";

const breakWord = {
  wordWrap: "break-word",
};

export default function SummaryDetailedItem({ title, description }) {
  return (
    <Grid item lg={6} xl={6} sm={6} paddingTop={2} gap={1}>
      <Grid>
        <Typography
          variant="body"
          fontSize={"12px"}
          color={"#10182F80"}
          fontWeight={500}
        >
          {title}
        </Typography>
      </Grid>
      <Typography variant="body" color={COLORS.black} mt={1} sx={breakWord}>
        {description}
      </Typography>
    </Grid>
  );
}
