import axios from 'axios';

export const about = async () => {
  const response = await axios.get('static_page/about_us');

  return response?.data;
};

export const why_choose = async () => {
  const response = await axios.get('static_page/why_choose');

  return response?.data;
};
