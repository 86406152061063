import React, { Fragment } from 'react';
import { TextInput } from '../form/TextField';
import { Box, Button, InputAdornment } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { forgotPassword } from '../../pages/Account/APIRequests.js';

function PasswordLogin({
  handleOTPLogin,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  isSubmitting,
  email,
  setAlertContent,
  setIsAlertOpened,
  setStep
}) {
  const handleForgotPassword = () => {
    const body = { email: email };
    forgotPassword(body);

    setAlertContent({
      severity: 'success',
      title: 'Email Sent Successfully',
      body: 'We have sent an email with instructions to reset your password. Please check your inbox and your junk/spam folder.'
    });

    setIsAlertOpened(true);
  };

  const loginEndIcon = isSubmitting ? (
    <CircularProgress size={20} sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />
  ) : null;

  const handleChangeClick = () => {
    setStep(1);
  };

  return (
    <Fragment>
      <TextInput
        htmlFor="Email"
        name="email"
        label="Email"
        title="Email"
        variant="filled"
        type="text"
        sx={{ marginBottom: 3 }}
        value={email}
        disabled={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button sx={{ mt: 0 }} onClick={handleChangeClick}>
                Change
              </Button>
            </InputAdornment>
          )
        }}
      />
      <TextInput
        htmlFor="Password"
        name="password"
        label="Password"
        title="Password"
        variant="filled"
        type="password"
        error={touched?.password && Boolean(errors?.password)}
        helperText={touched?.password && errors?.password}
        sx={{ marginBottom: 3 }}
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Button
        fullWidth={true}
        variant="contained"
        type="submit"
        disabled={isSubmitting}
        endIcon={loginEndIcon}>
        Log in
      </Button>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Button disableRipple variant="text" onClick={handleOTPLogin}>
          Log in using OTP
        </Button>
        <Button
          disableRipple
          variant="text"
          sx={{ color: 'rgba(16, 24, 47, 0.5)' }}
          onClick={handleForgotPassword}>
          Forgot password?
        </Button>
      </Box>
    </Fragment>
  );
}

export default PasswordLogin;
