import { Container, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { useQuery } from 'react-query';

import PlansGrid from './components/PlansGrid.js';
import { getWizardPlans } from './plans.api.js';
import Checklist from '../../components/Checklist.js';
import { COLORS } from '../../utils/colors.js';
import PageLoader from '../../components/PageLoader.js';
import { useNavigate, useParams } from 'react-router-dom';
import { checkSessionStorageItem } from '../../utils/sessionStorage.js';
import { GENERAL_CLIENT, SESSION_STORAGE_CLIENT } from '../../utils/constants.js';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import ServerError from '../Static/ServerError.js';

const mainContainerMargin = { sm: 3, xs: 3, md: 8, lg: 8, xl: 23 };
const introductionContainer = { xl: 'md', lg: 'md', md: 'xs', sm: 'xs' };

export default function PlansScreen() {
  const navigate = useNavigate();
  const { client: clientParam } = useParams();
  const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);

  const { data, isLoading, error } = useQuery(['getWizardPlans', client, hasClient], () =>
    getWizardPlans(client, hasClient)
  );

  React.useEffect(() => {
    if (clientParam) {
      sessionStorage.setItem(SESSION_STORAGE_CLIENT, clientParam);
    }
  }, [clientParam, data, navigate]);

  React.useEffect(() => {
    if (error?.response?.status == 403) {
      sessionStorage.setItem(SESSION_STORAGE_CLIENT, GENERAL_CLIENT);
      navigate('/plans');
    }
  }, [error, navigate]);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.plans.name);

  const { introduction, data: plans, services, logo_url } = data || {};

  const isPageLoading = () => isLoading && !error && <PageLoader />;

  const hasError = () => {
    !isLoading && error && <ServerError />;
  };

  const navigateToPlanForm = (id) => {
    try {
      const url = hasClient ? `/${client}/plan/${id}` : `/plan/${id}`;
      navigate(url);
    } catch (error) {
      // console.log({ error });
    }
  };

  const renderPlansContent = () => {
    return (
      !isLoading &&
      !error && (
        <Grid mb={7}>
          <Grid
            pt={12}
            alignItems="center"
            flexDirection={'column'}
            display={'flex'}
            ml={mainContainerMargin}
            mr={mainContainerMargin}>
            {logo_url && (
            <img
              src={process.env.REACT_APP_BACKEND_URL + logo_url}
              alt="client logo"
              style={{ width: '250px', marginBottom:'20px' }}
            />
          )}
            <Typography textAlign="center" variant="heading1">
              Plans
            </Typography>
            <Grid mt={2.75}>
              <Grid maxWidth={introductionContainer} alignItems={'center'} container>
                <Typography
                  textAlign={'center'}
                  variant="body"
                  dangerouslySetInnerHTML={{
                    __html: introduction
                  }}
                />
              </Grid>
            </Grid>
            <Grid pt={10} mb={10} width="100%">
              {plans?.length > 0 && <PlansGrid list={plans} onPlanClick={navigateToPlanForm} />}
            </Grid>
          </Grid>
          {services?.length > 0 && (
            <Container display={'flex'} maxWidth={'xl'}>
              <Grid>
                <Typography variant="headerDescription" fontWeight={'600'} color={COLORS.darkBlack}>
                  Services included
                </Typography>
                <Grid container mt={3}>
                  {services?.map((service, index) => (
                    <Grid key={index} item xl={4} lg={4} md={6} sm={6} xs={12} mb={3}>
                      <Checklist name={service} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Container>
          )}
        </Grid>
      )
    );
  };

  return (
    <Fragment>
      {isPageLoading()}
      {hasError()}
      {renderPlansContent()}
    </Fragment>
  );
}
