import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContactUsContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '20px',
  padding: '63px 46px',
  marginTop: '118px',
  marginBottom: '59px'
}));
