import { Box, Container, Grid, Typography } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import { useQuery } from 'react-query';
import { mobileApp } from './APIRequests.js';
import PageLoader from '../../components/PageLoader.js';
import Checklist from '../../components/Checklist.js';
import YouTubeVideo from '../../components/YoutubeVideo.js';
import OtherServices from '../../components/OtherServices.js';

import DownloadMobileApp from '../../components/DownloadMobileApp.js';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import ServerError from '../Static/ServerError.js';

const titleStyles = {
  fontFamily: 'Urbanist',
  fontSize: '20px',
  fontWeight: 700
};

const otherServicesTitleStyles = {
  fontFamily: 'Urbanist',
  fontWeight: 700,
  fontSize: '20px'
};

const boxStyles = {
  border: '2px solid #E8E8E8',
  borderRadius: '10px',
  width: '65%',
  padding: '40px 20px',
  display: { xs: 'none', md: 'block' }
};

export default function MobileApp() {
  const { data, isLoading, error } = useQuery('mobileApp', mobileApp);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.servicesMobileApp.name);

  return (
    <Fragment>
      {isLoading && <PageLoader />}
      {!isLoading && error && <ServerError />}
      {!isLoading && !error && (
        <Container maxWidth="xl">
          <Grid pt={12} pb={6} align="center">
            <Typography variant="heading1"> {data?.title}</Typography>
          </Grid>
          <Grid container columnSpacing={20}>
            <Grid item md={6} xs={12} sx={{ mb: 5 }}>
              <Typography sx={titleStyles} mb={2}>
                {data?.paragraphs.title_video_url.title}
              </Typography>
              <YouTubeVideo videoUrl={data?.paragraphs.title_video_url.video} />
              <Typography
                mt={2}
                mb={1}
                variant="body"
                paragraph={true}
                dangerouslySetInnerHTML={{
                  __html: data?.paragraphs.text.body
                }}
              />
              <Grid container>
                {data?.paragraphs.multiple_title_text?.map((service) => {
                  const { id, title, body } = service;

                  return (
                    <Grid key={id} item mt={2} md={12} xs={12}>
                      <Checklist name={title} description={body} />
                    </Grid>
                  );
                })}
              </Grid>
              <Typography sx={otherServicesTitleStyles} mt={5} mb={2}>
                {data?.paragraphs.services.title}
              </Typography>
              <OtherServices list={data?.paragraphs.services.services} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Box sx={boxStyles} align="center">
                <Typography sx={titleStyles} mb={2}>
                  {data?.paragraphs.title_text_image.title}
                </Typography>
                <img
                  src={process.env.REACT_APP_BACKEND_URL + data?.paragraphs.title_text_image.image}
                  alt="mobile_app_qr_code"
                  width={200}
                />
                <Typography
                  mt={2}
                  mb={1}
                  variant="body"
                  paragraph={true}
                  dangerouslySetInnerHTML={{
                    __html: data?.paragraphs.title_text_image.body
                  }}
                />
              </Box>

              <DownloadMobileApp
                title={data?.paragraphs?.download_app?.title}
                body={data?.paragraphs?.download_app?.body}
                play_store_url={data?.paragraphs?.download_app?.play_store_url}
                google_play_url={data?.paragraphs?.download_app?.google_play_url}
                image={data?.paragraphs?.download_app?.image}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </Fragment>
  );
}
