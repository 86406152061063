import * as Yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const validationSchema2 = Yup.object().shape({
  healthInsuranceProvider: Yup.string()
    .trim()
    .min(2, 'Health Insurance provider must be minimum 2 characters')
    .max(60, 'Health Insurance provider must not be more than 60 characters')
    .required('Health Insurance provider is required'),
  healthInsuranceMemberId: Yup.string()
    .trim()
    .min(2, 'Health Insurance Member Id must be minimum 2 characters')
    .max(30, 'Health Insurance Member Id must not be more than 30 characters')
    .required('Health Insurance Member Id is required'),
  emergencyContactName: Yup.string()
    .trim()

    .min(2, 'Name must be minimum 2 characters')
    .max(30, 'Name must not be more than 30 characters'),
  emergencyContactRelationship: Yup.string().when('emergencyContactName', {
    is: (val) => val && val.trim() !== '',
    then: () =>
      Yup.string()
        .trim()
        .required(
          'Emergency Contact Relationship is required because you added an emergency contact name.'
        )
  }),
  emergencyContactPhoneNumber: Yup.string().when('emergencyContactName', {
    is: (val) => val && val.trim() !== '',
    then: () =>
      Yup.string()
        .required('Phone number is required because you added an emergency contact name.')
        .test('is-valid-phone', 'Phone number is not valid', (value) => {
          const phoneWithCountryCode = value.startsWith('+') ? value : `+${value}`;
          const phoneNumber = parsePhoneNumberFromString(phoneWithCountryCode);

          return phoneNumber ? phoneNumber.isValid() : false;
        })
  })
});
