import axios from 'axios';
import { getAccessToken, refreshToken, setAccessToken } from './Auth.js'; // functions to handle tokens
import { SESSION_STORAGE_REFRESH_TOKEN } from './utils/constants.js';
import { clearUserInfo } from './utils/sessionStorage.js';

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.baseURL = process.env.REACT_APP_API_URL;
    config.headers.Accept = 'application/json';
    config.headers['Content-Type'] = 'application/json';

    if (config.requiresAuth) {
      const token = getAccessToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== '/validate-forgot-password-token' &&
      originalRequest.url !== `${process.env.REACT_APP_API_URL}token/refresh`
    ) {
      originalRequest._retry = true;

      try {
        const response = await refreshToken();
        setAccessToken(response.access_token);
        sessionStorage.setItem(SESSION_STORAGE_REFRESH_TOKEN, response.refresh_token);

        originalRequest.headers.Authorization = `Bearer ${response.access_token}`;

        return axios(originalRequest);
      } catch (refreshError) {
        clearUserInfo();
        window.location.href = '/login';

        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);
