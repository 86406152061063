import { validationSchema2 } from './step2.scheme';

const generateInitialValues = (prefill) => {
  if (prefill) {
    return {
      healthInsuranceProvider: prefill.healthInsuranceProvider || '',
      healthInsuranceMemberId: prefill.healthInsuranceMemberId || '',
      emergencyContactName: prefill.emergencyContactName || '',
      emergencyContactRelationship: prefill.emergencyContactRelationship || '',
      emergencyContactPhoneNumber: prefill.emergencyContactPhoneNumber || ''
    };
  } else {
    return {
      healthInsuranceProvider: '',
      healthInsuranceMemberId: '',
      emergencyContactName: '',
      emergencyContactRelationship: '',
      emergencyContactPhoneNumber: ''
    };
  }
};

const step2FormikConfig = (values, enableReinitialize) => {
  return {
    initialValues: generateInitialValues(values),
    validationSchema: validationSchema2,
    enableReinitialize: enableReinitialize
  };
};

export default step2FormikConfig;
