import { validationSchema4 } from './step4.scheme';

const generateInitialValues = (prefill) => {
  const { address, city, state, country, zipCode } = prefill || {};

  let initialValues = {
    address: address || '',
    city: city || '',
    country: country || '',
    state: state || '',
    zipCode: zipCode || '',
    nameOnCard: '',
    cardNumber: '',
    expirationDate: '',
    securityCode: '',
    discountCode: '',
    agreeToConditions: ''
  };

  return initialValues;
};

const step4FormikConfig = (plan) => {
  return {
    initialValues: generateInitialValues(plan),
    validationSchema: validationSchema4,
    enableReinitialize: true
  };
};

export default step4FormikConfig;
