import React from 'react';
import { Grid } from '@mui/material';

const AuthorizeDisplay = () => {
  const handleMouseOver = () => {
    window.status = 'http://www.authorize.net/';

    return true;
  };

  const handleMouseOut = () => {
    window.status = '';

    return true;
  };

  const handleClick = (event) => {
    event.preventDefault();

    window.open(
      'https://verify.authorize.net/anetseal/?pid=9cacfbc5-54c0-4f5f-b198-c47203a33110&amp;rurl=https://www.globaltravelplus.com',
      'AuthorizeNetVerification',
      'width=600,height=430,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes'
    );

    return false;
  };

  return (
    <Grid display="flex" alignItems={'center'} justifyContent={'center'} mt={3.5}>
      <a
        href="https://verify.authorize.net/anetseal/?pid=9cacfbc5-54c0-4f5f-b198-c47203a33110&amp;rurl=https://www.globaltravelplus.com"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleClick}
        rel="noopener noreferrer"
        target="_blank">
        <img
          src="https://verify.authorize.net/anetseal/images/secure90x72.gif"
          width="90"
          height="72"
          border="0"
          alt="Authorize.Net Merchant - Click to Verify"
        />
      </a>
    </Grid>
  );
};

export default AuthorizeDisplay;
