export const MAX_NB_OF_SERVICES_CARDS_PER_ROW = 3;

export const MAX_NB_OF_OFFERS_CARDS_PER_ROW = 3;

export const CURRENCY = '$';

export const STEPPER_STEPS = [
  'Personal Info',
  'Insurance & Contact Info',
  'Plan Type',
  'Payment Info'
];

export const PLAN_TYPES = {
  SINGLE: 'single',
  DUAL: 'dual',
  FAMILY: 'family'
};

export const QUERY_PARAM_URL = 'client';

export const SESSION_STORAGE_CLIENT = 'gtpClient';

export const SESSION_STORAGE_EMAIL = 'email';

export const SESSION_STORAGE_FIRSTNAME = 'firstname';

export const SESSION_STORAGE_LASTNAME = 'lastname';

export const SESSION_STORAGE_IS_LOGGED_IN = 'isLoggedIn';

export const SESSION_STORAGE_INITIALS = 'initials';

export const SESSION_STORAGE_ACCESS_TOKEN = 'access_token';

export const SESSION_STORAGE_PLAN_ID = 'plan_id';

export const SESSION_STORAGE_REFRESH_TOKEN = 'refresh_token';

export const GENERAL_CLIENT = 'general';

export const GLOBAL_TRAVEL_PLUS = 'Global Travel Plus';

export const getTitleForPath = (path) => {
  if (path === '/') {
    return 'Global Travel Plus';
  }

  if (path === '/services') {
    return 'Services';
  }

  if (path === '/blogs') {
    return 'Travel Blogs';
  }

  if (path === '/plans') {
    return 'Plans';
  }

  if (path.startsWith('/blogs/')) {
    return 'Blog';
  }

  if (path.startsWith('/plan/')) {
    return 'GTP Wizard';
  }

  if (path === '/about-us') {
    return 'About Us';
  }

  if (path === '/why-choose') {
    return 'Why Choose';
  }

  if (path === '/faq') {
    return 'FAQ';
  }

  if (path === '/contact-us') {
    return 'Contact Us';
  }

  if (path === '/login') {
    return 'Login';
  }

  if (path === '/forgot-password') {
    return 'Forgot Password';
  }

  if (path === '/reset-password') {
    return 'Reset Password';
  }

  if (path === '/profile') {
    return 'Profile';
  }

  if (path === '/coverage-letter') {
    return 'Coverage Letter';
  }

  if (path === '/travel-information') {
    return 'Travel Information';
  }

  if (path === '/terms') {
    return 'Terms';
  }

  if (path === '/privacy') {
    return 'Privacy';
  }

  if (path === '/services/extended-travel-program') {
    return 'Extended Travel Program';
  }

  if (path === '/services/mobile-app') {
    return 'Services Mobile App';
  }

  if (path === '/services/pet-assistance') {
    return 'Services Pet Assistance';
  }

  if (path === '/services/j1-visa') {
    return 'Services J1 Visa';
  }

  if (path.startsWith('/welcome/')) {
    return 'Welcome';
  }

  {
    return GLOBAL_TRAVEL_PLUS;
  }
};

export const PLAN_UPADTE_MODE = 'update';

export const PLAN_FILL_MODE = 'fill';

export const PLAN_RENEW_MODE = 'renew';
