import React, { useEffect, useState } from 'react';
import { Button, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import { COLORS } from '../../../../utils/colors';
import { TextInput } from '../../../../components/form/TextField';
import ReactPhoneInput from 'react-phone-input-material-ui';
import 'react-phone-input-material-ui/lib/style.css';
import { validationSchema2 } from './step2.scheme';
import { ActionButtonsContainer, CenterFormGrid } from '../../Wizard.styles';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import _ from 'lodash';
import { GOOGLE_ANALYTICS_LABELS, trackPageview } from '../../../../utils/analytics';
import useTrackScrollDepth from '../../../../hooks/useTrackScrollDepth';

const Step2 = ({ handleSubmit, formik, onPreviousClick }) => {
  const [stepError, setStepError] = useState('');
  const hasError = !_.isEmpty(formik.errors);

  useEffect(() => {
    trackPageview(GOOGLE_ANALYTICS_LABELS.wizardStep2);
  }, []);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.wizardStep2.name);

  const handleSubmitSecondStep = async (values) => {
    const validateFormRes = await formik.validateForm();
    const isValid = _.isEmpty(validateFormRes);

    if (isValid && formik.dirty) {
      handleSubmit(values);
      setStepError(false);
    }

    if (isValid && !formik.dirty) {
      handleSubmit(values);
      setStepError(false);
    } else {
      const touchedFields = Object.keys(validationSchema2.fields).reduce((acc, key) => {
        acc[key] = true;

        return acc;
      }, {});

      formik.setTouched(touchedFields);
      setStepError(true);
      window.scrollTo(0, 130);
    }
  };

  const onPhoneChange = (value, country) => {
    formik.setFieldValue('countryDialCode', country.dialCode);
    formik.setFieldValue('countryIsoCode', country.countryCode.toUpperCase());
    formik.setFieldValue('emergencyContactPhoneNumber', formatPhoneNumberIntl(`+${value}`));
  };

  return (
    <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
      <CenterFormGrid>
        <Grid mb={3}>
          {hasError && stepError && (
            <Grid mb={0.8}>
              <Typography variant="body" fontWeight={500} color={COLORS.error}>
                Please ensure all fields are filled in before proceeding.
              </Typography>
            </Grid>
          )}
          <Typography variant="body" fontWeight={700} color={COLORS.black}>
            Insurance Information
          </Typography>
        </Grid>
        <Grid mt={2}>
          <TextInput
            htmlFor="healthInsuranceProvider"
            name="healthInsuranceProvider"
            label="Health Insurance Provider"
            title="healthInsuranceProvider"
            variant="filled"
            onChange={formik.handleChange}
            value={formik.values.healthInsuranceProvider}
            error={
              formik?.touched?.healthInsuranceProvider &&
              Boolean(formik?.errors?.healthInsuranceProvider)
            }
            helperText={
              formik?.touched?.healthInsuranceProvider && formik?.errors?.healthInsuranceProvider
            }
            maxLength={60}
          />
        </Grid>
        <Grid mt={2}>
          <TextInput
            fullWidth
            label="Health Insurance Member ID"
            id="healthInsuranceMemberId"
            name="healthInsuranceMemberId"
            value={formik.values.healthInsuranceMemberId}
            onChange={formik.handleChange}
            error={
              formik?.touched?.healthInsuranceMemberId &&
              Boolean(formik?.errors?.healthInsuranceMemberId)
            }
            helperText={
              formik?.touched?.healthInsuranceMemberId && formik?.errors?.healthInsuranceMemberId
            }
          />
        </Grid>
        <Grid mb={3} mt={5}>
          <Typography variant="body" fontWeight={700} color={COLORS.black}>
            Emergency Contact Information
          </Typography>
          <Grid>
            <Typography variant="smallText">Optional</Typography>
          </Grid>
          <Grid item mt={2}>
            <TextInput
              fullWidth
              label="Emergency Contact Name"
              id="emergencyContactName"
              name="emergencyContactName"
              value={formik.values.emergencyContactName}
              onChange={formik.handleChange}
              error={
                formik?.touched?.emergencyContactName &&
                Boolean(formik?.errors?.emergencyContactName)
              }
              helperText={
                formik?.touched?.emergencyContactName && formik?.errors?.emergencyContactName
              }
            />
          </Grid>
          <Grid mt={2}>
            <TextInput
              htmlFor="emergencyContactRelationship"
              name="emergencyContactRelationship"
              label="Emergency Contact Relationship"
              title="emergencyContactRelationship"
              variant="filled"
              onChange={formik.handleChange}
              value={formik.values.emergencyContactRelationship}
              error={
                formik?.touched?.emergencyContactRelationship &&
                Boolean(formik?.errors?.emergencyContactRelationship)
              }
              helperText={
                formik?.touched?.emergencyContactRelationship &&
                formik?.errors?.emergencyContactRelationship
              }
            />
          </Grid>
          <Grid mt={2} mb={3}>
            <ReactPhoneInput
              enableSearchField={true}
              value={formik.values.emergencyContactPhoneNumber}
              label={null}
              onChange={(value, country) => onPhoneChange(value, country)}
              component={TextField}
              placeholder={'8023456789'}
              country={'us'}
              localization={'en'}
              name={'emergencyContactPhoneNumber'}
              id={'emergencyContactPhoneNumber'}
              autoFormat={true}
              countryCodeEditable={true}
              inputProps={{
                name: 'emergencyContactPhoneNumber',
                margin: 'normal',
                required: false
              }}
              inputStyle={{ width: '100%' }}
            />
            {formik?.touched?.emergencyContactPhoneNumber && (
              <FormHelperText>{formik.errors.emergencyContactPhoneNumber}</FormHelperText>
            )}
          </Grid>

          <ActionButtonsContainer mt={7} container gap={1}>
            <Button variant="outlined" onClick={onPreviousClick}>
              Previous
            </Button>
            <Button variant="contained" type="submit" onClick={handleSubmitSecondStep}>
              Next
            </Button>
          </ActionButtonsContainer>
        </Grid>
      </CenterFormGrid>
    </Grid>
  );
};

export default Step2;
