import { Grid } from '@mui/material';
import React from 'react';
import animationData from '../static/loader.json';
import LottieAnimation from './LottieAnimation';

function Loader() {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item width={150}>
        <LottieAnimation animationData={animationData} looping={true} />
      </Grid>
    </Grid>
  );
}

export default Loader;
