import * as React from 'react';
import Banner from '../../components/homepage/Banner.js';
import Services from '../../components/homepage/Services.js';
import Features from '../../components/homepage/Features.js';
import Plans from '../../components/homepage/Plans.js';
import Blogs from '../../components/homepage/Blogs.js';
import Pet from '../../components/homepage/Pet.js';
import Feedbacks from '../../components/homepage/Feedbacks.js';
import PageLoader from '../../components/PageLoader.js';
import { Grid } from '@mui/material';

import { useQuery } from 'react-query';
import { banner, services, features, plans, blogs, pet, feedbacks } from './APIRequests.js';
import { GENERAL_CLIENT, SESSION_STORAGE_CLIENT } from '../../utils/constants.js';

import { useNavigate, useParams } from 'react-router-dom';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import ServerError from '../Static/ServerError.js';

export default function Homepage() {
  const { client: clientParam } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (clientParam) {
      sessionStorage.setItem(SESSION_STORAGE_CLIENT, clientParam);
    }
  }, [clientParam]);

  const {
    data: bannerData,
    isLoading: bannerDataIsLoading,
    error: bannerDataError
  } = useQuery('banner', banner);

  const {
    data: servicesData,
    isLoading: servicesDataIsLoading,
    error: servicesDataError
  } = useQuery('homeServices', services);

  const {
    data: featuresData,
    isLoading: featuresDataIsLoading,
    error: featuresrDataError
  } = useQuery('features', features);

  const {
    data: plansData,
    isLoading: plansDataIsLoading,
    error: plansDataError,
    refetch: plansRefetch
  } = useQuery(['plans'], plans);

  const {
    data: blogsData,
    isLoading: blogsbannerDataIsLoading,
    error: blogsbannerDataError
  } = useQuery('blogs', blogs);

  const { data: petData, isLoading: petDataIsLoading, error: petDataError } = useQuery('pet', pet);

  const {
    data: feedbacksData,
    isLoading: feedbacksDataIsLoading,
    error: feedbacksDataError
  } = useQuery('feedbacks', feedbacks);

  React.useEffect(() => {
    if (plansData?.data === 'Client ID Not Found') {
      sessionStorage.setItem(SESSION_STORAGE_CLIENT, GENERAL_CLIENT);
      plansRefetch();
      navigate('/');
    }
  }, [plansData, navigate, plansRefetch]);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.home.name);

  const isPageLoading =
    bannerDataIsLoading ||
    servicesDataIsLoading ||
    featuresDataIsLoading ||
    plansDataIsLoading ||
    blogsbannerDataIsLoading ||
    petDataIsLoading ||
    feedbacksDataIsLoading;

  const pageError =
    bannerDataError ||
    servicesDataError ||
    featuresrDataError ||
    plansDataError ||
    blogsbannerDataError ||
    petDataError ||
    feedbacksDataError;

  return (
    <>
      {isPageLoading && <PageLoader />}
      {!isPageLoading && pageError && <ServerError />}
      {!isPageLoading && !pageError && (
        <Grid>
          {bannerData?.length > 0 && (
            <Banner
              title={bannerData && bannerData[0]?.field_block_title}
              button={bannerData && bannerData[0]?.field_button_text}
              logo={bannerData && bannerData[0]?.field_logo_url}
            />
          )}
          {featuresData?.data?.length > 0 && (
            <Features
              title={featuresData?.title}
              description={featuresData?.text}
              list={featuresData?.data}
            />
          )}
          {servicesData?.data?.length > 0 && (
            <Services
              title={servicesData?.title}
              description={servicesData?.text}
              list={servicesData?.data}
              button={servicesData?.button_text}
            />
          )}
          {Array.isArray(plansData?.data) && plansData?.data?.length > 0 && (
            <Plans
              title={plansData?.title}
              description={plansData?.text}
              list={plansData?.data}
              button={plansData?.button_text}
            />
          )}
          {feedbacksData?.data?.length > 0 && (
            <Feedbacks
              title={feedbacksData?.title}
              description={feedbacksData?.text}
              list={feedbacksData?.data}
            />
          )}
          {blogsData?.data?.length > 0 && (
            <Blogs
              title={blogsData?.title}
              description={blogsData?.text}
              list={blogsData?.data}
              button={blogsData?.button_text}
            />
          )}
          {petData?.length > 0 && (
            <Pet
              title={petData && petData[0]?.field_block_title}
              description={petData && petData[0]?.body}
              button={petData && petData[0]?.field_button_text}
              image={
                petData &&
                petData[0]?.field_image &&
                process.env.REACT_APP_BACKEND_URL + petData[0]?.field_image
              }
            />
          )}
        </Grid>
      )}
    </>
  );
}
