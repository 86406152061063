import axios from 'axios';

export const privacy = async () => {
  const response = await axios.get('static_page/privacy_policy');

  return response?.data;
};

export const terms = async () => {
  const response = await axios.get('static_page/terms_and_conditions');

  return response?.data;
};

export const faq = async () => {
  const response = await axios.get('static_page/faq');

  return response?.data;
};
