import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

function DownloadMobileApp({
  title,
  body,
  play_store_url,
  google_play_url,
  image,
  showOnLargeScreens = false
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleStoreClick = (url) => {
    window.open(url, '_blank');
  };

  const downloadAppBox = {
    display: { xs: 'block', md: 'none' }
  };

  const downloadAppTitleStyles = {
    fontFamily: 'Urbanist',
    fontWeight: 600,
    fontSize: '30px'
  };

  const downloadAppImage = (image, showOnLargeScreens) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    width: '300px',
    height: '180px',
    margin: showOnLargeScreens && !isMobile ? '20px 200px 0 auto' : '20px auto 0 auto'
  });

  return (
    <Box sx={showOnLargeScreens ? null : downloadAppBox} xs={12}>
      <Typography sx={downloadAppTitleStyles}>{title}</Typography>
      <Typography
        mt={2}
        mb={1}
        variant="body"
        paragraph={true}
        fontWeight={500}
        dangerouslySetInnerHTML={{
          __html: body
        }}
      />
      <Box display="flex" justifyContent="flex-start" mt={2}>
        <Box
          component="img"
          src="/images/apple_store.svg"
          alt="Download on the App Store"
          onClick={() => handleStoreClick(play_store_url)}
          width={150}
          sx={{ cursor: 'pointer', marginRight: 2 }}
        />
        <Box
          component="img"
          src="/images/google_play.svg"
          alt="Get it on Google Play"
          onClick={() => handleStoreClick(google_play_url)}
          width={150}
          sx={{ cursor: 'pointer' }}
        />
      </Box>
      <Box
        sx={downloadAppImage(process.env.REACT_APP_BACKEND_URL + image, showOnLargeScreens)}></Box>
    </Box>
  );
}

export default DownloadMobileApp;
